
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {margin: 0; height: 100%; overflow-y:scroll;}



body::-webkit-scrollbar {
  -webkit-appearance: none;
}
body::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 0);width:0px;background-color: #fff;} 

body::-webkit-scrollbar{width: 0px;background-color: #fff;}

body::-webkit-scrollbar-thumb{background-color: #fff;width:0px;}