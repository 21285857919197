@font-face {
  font-family: "SpaceGrotesk-Bold";
  font-weight: bold;
  src: url("../public/fonts/SpaceGrotesk-Bold.ttf") format("ttf"),
    url("../public/fonts/SpaceGrotesk-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "SpaceGrotesk-Medium";
  font-weight: medium;
  src: url("../public/fonts/SpaceGrotesk-Medium.ttf") format("ttf"),
    url("../public/fonts/SpaceGrotesk-Medium.ttf") format("ttf");
}
* {
  font-family: "Space Grotesk", sans-serif;
}
.battery-container {
  position: relative;
  width: 70%;
  height: 110px;
  margin-right: -1rem;
  margin-bottom: 2rem;
}

.battery-filled {
  position: absolute;
  background-color: yellow;
  width: calc(30% - 10px);
  height: 82px;
  margin-top: 3px;
  border-radius: 10px;
  left: -2px;
}

.battery-image {
  position: absolute;
  top: 0;
  left: -1rem;
  right: 0;
  bottom: 0;
  background-image: url("./assets/icons/battery.png"); /* set the path to your battery image */
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% 80%;
}

.title_battery_level {
  color:#2C2B29;
  font-size: 23px;
  font-weight: 700;
}

.container_wrapper {
  padding: 0px 19px 0px 19px;
  scrollbar-width: none;
  /* height: 100vh; */
}
.container_wrapper::-webkit-scrollbar { 
  width: 0;
  display: none;
}
::-webkit-scrollbar{
  display: none;
  -webkit-appearance: none;
  overflow: hidden;
  width: 0 !important
}
body::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  overflow: hidden;
  width: 0 !important
}

body::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  overflow: hidden;
  width: 0 !important
}

/* Track */
body::-webkit-scrollbar-track {
  display: none;
  -webkit-appearance: none;
  overflow: hidden;
  width: 0 !important
}
 
/* Handle */
body::-webkit-scrollbar-thumb {
  display: none;
  -webkit-appearance: none;
  overflow: hidden;
  width: 0 !important
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  display: none;
  -webkit-appearance: none;
  overflow: hidden;
  width: 0 !important
}

@media screen and (min-width: 600px) {
  .container_wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -1%);
    width: 320px;
  }
}

.box {
  width: 41%;
  background: #ddf3f1;
  box-shadow: 0px 10.9101px 29.0935px rgba(199, 184, 184, 0.16);
  border-radius: 6px;
  padding: 10px 8px;
  font-weight: bold;
}

.box-title {
  font-size: 12px;
  color:#2c2b29;
}

.box-value {
  margin-top: 7px;
  color:#2c2b29;
  font-size: 23px;
}

.charge-level {
  color: #2C2B29;
  font-weight: bold;
}

.slot-number {
  font-weight: bolder;
  margin-bottom: 4px;
}
.charge-level-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.charge-level-value {
  font-weight: bold;
  font-size: 27px;
  margin-top: 10px;
  text-align: end;
  color:#404040;
}


.vehicle_status_wrapper {
  width: 100%;
  font-weight: bold;
}
.vehicle_status_wrapper div:first-child {
  /* CSS properties go here */
  font-size: 0.8em;
  color: #2c2b29;
  white-space: nowrap;
}
.vehicle_status_wrapper div:last-child {
  margin-top: 4px;
  color: #263967;
  font-size: 1em !important;
  text-transform: uppercase;
}

.vehicle_stats_wrapper {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.vehicle_stats {
  width: auto;
  background-color: #ddf3f1;
  box-shadow: 0px 10.9101px 29.0935px rgba(199, 184, 184, 0.16);
  border-radius: 6px;
  padding: 10px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
}
.emissions_savings_wrapper {
  width: auto;
  background-color: #ffed92;
  display: flex;
  margin-top: 3px;
  /* font-weight: bold; */
  font-size: 0.8em;
  color: black;
  margin-right: 3px;
  background: #ffed92;
  padding: 5px;
  border-radius: 5px;
  height: 12px;
  white-space: nowrap;

}
.emissions_savings_wrapper > div {
  color: #263967;
  font-size: 14px !important;
  /* font-weight: bold; */
  margin-top: -2px;
  margin-right: 2px;
  margin-left: 3px;
}

.vehicle_stats div:last-child {
  font-weight: bold;
  font-size: 15px;
}
.battery_disconnet_wrapper {
  font-size: 15px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  letter-spacing: -1px !important;
  font-weight: normal !important;
}
.container_wrapper_battery {
  overflow-y: hidden;
  height: 96.4vh;
  margin-top:20px;
}
.stats_wrapper {
  display: flex;
  margin-top: 25px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.location_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  
}
.location_wrapper > img {
  margin-right: 10px;
  position: relative;
  object-fit: contain;
}

.location_wrapper > div {
  font-weight: bold;
  font-size: 0.7em;
  margin-top: 2px;
}

.battery_wrapper {
  position: relative;
  display:flex;
  justify-content: center;
}

.battery_title {
  font-weight: bold;
  font-size: 1.1em;
  position: absolute;
  left: 33%;
  top: 1.3rem;
  z-index: 1000;
  color: black;
  font-family: "Space Grotesk", sans-serif;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: black;
}
.battery_percentage {
  font-weight: bold;
  font-size: 1.9em;
  position: absolute;
  right: 30px;
  top: 0.5rem;
  z-index: 100;
}

.trapezoid {
  width: 300px;
  height: 60px;
  background: #e9e9e9;
  transform: perspective(600px) rotateX(60deg);
  /* border-bottom: 20px solid #F0C900; */
  /* border-top: 3px solid #e9e9e9; */
}
.trapezoid::first-child {
  position: relative;
}
.battery_filled {
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: #23bca9;
  z-index: 1;
}
.bottom-battery{transform: perspective(900px) rotateX(360deg) scale(1.044) !important;
  height: 8px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;}
  .bottom-battery-wrapper{
    top: -15px;
  }
   .bottom-battery-wrapper .battery-tick{height: 10px !important;}
.battery-tick-container {
  display: flex;
  position: relative;
  z-index: 1000;
}
.battery-tick {
  background-color: white;
  width: 1px;
  height: 60px;
  margin-left: 28px;
}

.container_vehicle {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.box_vehicle {
  width: 150px;
  background: #E7E7E7;
  box-shadow: 0px 10.9101px 29.0935px rgba(199, 184, 184, 0.16);
  border-radius: 6px;
  padding: 10px 0px 10px 0px;
  display: flex;
  margin-left: 10px;
  display:flex;
  justify-content:center;
}

.container_vehicle img {
  width: 40px;
  object-fit: contain;
}

.margin-bottom {
  padding-bottom: 20px !important;
}

.box_vehicle > div > div:first-child {
  font-size: 0.8em;
  font-weight: bold;
  text-align: center !important;

}

.box_vehicle > div > div:last-child {
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 4px;
  display:flex;
  justify-content: center;
}
.eco_dost_container {
  display: flex;
  margin-top: 3px;
}
.fuel_icon {
  margin-top: 10px;
}
.find_ecodost {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  align-content: center;
  align-items: center;
  color: #8F8C8C;
  font-weight: bold;
}

.remaining_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  text-align: center;
  color: #8F8C8C;
  font-weight: bold;
}
.row-flex-center {
  display: flex;
  justify-content: center;
}
.kiloneter_container {
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.8em;
  margin-top: 15px;
  color:black;
}
.kiloneter_value {
  font-weight: bold;
  margin-top: 10px;
  font-size: 2.3em;
  color:black;
}
.cloud {
  width: 200.42px;
  height: 16px;
  border-radius: 80%;
  background: rgb(0, 0, 0, 1);
  mix-blend-mode: multiply;
  opacity: 0.25;
  filter: blur(5.5px);
  position: absolute;
  bottom: -0.8rem;
  left: -1rem;
}
.polygon {
  position: absolute;
  width: 28.27px;
  height: 28.27px;
  left: 57px;
  top: 315.49px;

  background: #404040;
  border-radius: 1px;
}

.battery-filled:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #404040;
  display: block;
  position: relative;
  bottom: -5.5rem;
  float: right;
}
.emission_saving_wrapper {
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddf3f1;
}
.emission_saving_wrapper > div {
  margin-top: 14px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #263967;
}

.tree_wrapper {
  margin-top: 100px;
  font-weight:bold;
}
.tree_wrapper > div:nth-child(1) {
  font-size:27px;
  margin-bottom:5px
}
.tree_wrapper > div:nth-child(3) {
  margin-top:20px
}
.tree_wrapper > div:nth-child(4) {
  font-size:25px;
  margin-top:-10px;
  margin-bottom:15px
}
.tree_wrapper > div:nth-child(5) {
  font-size:18px;
}
.tree_wrapper > div:nth-child(6) {
  font-size:11px;
  margin-bottom:20px
}
.tree_wrapper > div:nth-child(8) {
  font-size:25px;
}
.tree_wrapper > div:nth-child(9) {
  font-size:18px;
  margin-bottom:13px
}
.tree_wrapper > div:nth-child(10) {
  font-size:13px;
}
#myMap{
  height:100vh;
  width:100%
}



.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgb(0,0,0,0);
  z-index:10000;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

